<template>
  <v-container id="user_mgr-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('user_mgr.title')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <v-dialog
          v-model="upload_form"
          max-width="500px"
          @click:outside="closeUpload"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="ocean" dark class="mx-2 mb-2" v-on="on">
              <v-icon left>mdi-account-edit</v-icon>
              {{ $t("common.upload") }}{{ $t("user.manual") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ $t("common.upload") }}{{ $t("user.manual") }}</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          v-model="uploadFile"
                          accept=".pdf"
                          :label="$t('common.importFile')"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeUpload">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="sendFile">
                {{ $t("common.upload") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog_form"
          max-width="500px"
          @click:outside="close"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="createUser"
            >
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("user_mgr.createUser") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline" v-if="newUser">{{
                $t("user_mgr.createUser")
              }}</span>
              <span class="headline" v-if="!newUser">{{
                $t("user_mgr.updateUser")
              }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.username"
                        :rules="fieldRules"
                        :label="$t('user_mgr.username')"
                        :readonly="!newUser"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.first_name"
                        :rules="fieldRules"
                        :label="$t('user_mgr.first_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.nickname"
                        :rules="fieldRules"
                        :label="$t('user_mgr.nickname')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" v-if="newUser">
                      <v-text-field
                        v-model="item.password1"
                        :rules="fieldRules"
                        :type="showPassword ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :label="$t('user_mgr.password1')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" v-if="newUser">
                      <v-text-field
                        v-model="item.password2"
                        :rules="fieldRules"
                        :type="showPassword ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :label="$t('user_mgr.password2')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="item.role"
                        :items="CreateUserRoleItems"
                        item-value="value"
                        :label="$t('user_mgr.userRole')"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ $t(data.item.text) }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ $t(data.item.text) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.email')"
                        :rules="fieldRules"
                        class="purple-input"
                        v-model="item.email"
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.phone')"
                        :rules="fieldRules"
                        class="purple-input"
                        v-model="item.phone"
                      />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.max_urban_renewal')"
                        class="purple-input"
                        v-model="item.max_urban_renewal"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.max_issue')"
                        class="purple-input"
                        v-model="item.max_issue"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.line_account')"
                        class="purple-input"
                        v-model="item.line_account"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('user_mgr.company_name')"
                        class="purple-input"
                        v-model="item.company_name"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :label="$t('user_mgr.job_title')"
                        class="purple-input"
                        v-model="item.job_title"
                      />
                    </v-col>
                    <template v-if="item.company">
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('company_mgr.name')"
                          class="purple-input"
                          v-model="item.company.name"
                          readonly
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('company_mgr.ID')"
                          class="purple-input"
                          v-model="item.company.company_id"
                          readonly
                        />
                      </v-col>
                    </template>
                    <v-col cols="6" v-else>
                      <v-text-field
                        :label="$t('user_mgr.per_charge')"
                        class="purple-input"
                        v-model="item.per_charge"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn
                v-if="newUser"
                color="blue darken-1"
                text
                :disabled="loading"
                @click="save"
              >
                {{ $t("common.save") }}
              </v-btn>
              <v-btn v-if="!newUser" color="blue darken-1" text @click="update">
                {{ $t("common.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      </v-col>
      <v-col class="text-right">
        <v-dialog
          v-model="dialog_password"
          max-width="500px"
          @click:outside="closePassword"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("user_mgr.resetPassword") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="resetItem.username"
                      :label="$t('user_mgr.username')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="resetItem.nickname"
                      :label="$t('user_mgr.nickname')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="resetItem.password1"
                      :type="showPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :label="$t('user_mgr.resetPassword1')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="resetItem.password2"
                      :type="showPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :label="$t('user_mgr.resetPassword2')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closePassword">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="resetPassword">
                {{ $t("user_mgr.resetPassword") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.role="{ item }">
          <span>{{ getRole(item.role) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item)"
              >{{ $t("common.profile") }}</v-btn
            >
            <v-btn
              color="secondary"
              class="mx-2"
              @click="resetUserPassword(item)"
              small
              >{{ $t("user_mgr.resetPassword") }}</v-btn
            >
            <v-btn color="red" class="mx-2" small @click="RemoveUser(item)">{{
              $t("common.delete")
            }}</v-btn>
            <!-- 儲值 -->
            <v-btn v-if="item.role == UserRole.Common" color="ocean" class="mx-2" @click="toCashFlow(item)" small>{{
              $t("user_mgr.cashflow")
            }}</v-btn>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t("common.noData") }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { UserRole, UserRoleItems, CreateUserRoleItems } from "@/definition";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  data: (vm) => ({
    UserRole: UserRole,
    UserRoleItems: UserRoleItems,
    CreateUserRoleItems: CreateUserRoleItems,
    dialog_message: false,
    valid: true,
    meeting: { title: null },
    items: [],
    totalItems: 0,
    loading: false,
    dialog_form: false,
    dialog_password: false,
    upload_form: false,
    uploadFile: null,
    options: {},
    newUser: true,
    userId: null,
    showPassword: false,
    message: {
      title: "",
      text: "",
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.username"),
        value: "username",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.nickname"),
        value: "nickname",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.email"),
        value: "email",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.phone"),
        value: "phone",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.userRole"),
        value: "role",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: "",
        value: "actions",
      },
    ],
    item: {
      username: "",
      first_name: "",
      password1: "",
      password2: "",
      email: "",
      nickname: "",
      phone: "",
      role: UserRole.Common,
      max_urban_renewal: 0,
      max_issue: 0,
      line_account: "",
      company_name: "",
      job_title: "",
      company: null,
      company_id: null,
      per_charge: 7,
    },
    resetItem: {
      username: "",
      password1: "",
      password2: "",
    },
    meetingItems: [],
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    expired_time_format: null,
  }),
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    closeUpload() {
      this.upload_form = false;
      this.loading = false;
    },
    closePassword() {
      this.dialog_password = false;
      this.loading = false;
    },
    getRole(role) {
      let roleStr = "";
      this.UserRoleItems.forEach((element) => {
        if (element.value === role) {
          roleStr = element.text;
        }
      });
      return this.$i18n.t(roleStr);
    },
    createUser() {
      this.$refs.form.resetValidation();
      this.newUser = true;
      this.item.username = "";
      this.item.first_name = "";
      this.item.password1 = "";
      this.item.password2 = "";
      this.item.email = "";
      this.item.nickname = "";
      this.item.phone = "";
      this.item.role = UserRole.Common;
      this.item.max_urban_renewal = 0;
      this.item.max_issue = 0;
      this.item.line_account = "";
      this.item.company_name = "";
      this.item.job_title = "";
      this.item.company_id = null;
      this.item.per_charge = 7;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          //   task: this.id
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data.results;
    },
    showDetail(user) {
      this.dialog_form = true;
      this.newUser = false;
      this.userId = user.id;
      this.item.username = user.username;
      this.item.first_name = user.first_name;
      this.item.nickname = user.nickname;
      this.item.email = user.email;
      this.item.phone = user.phone;
      this.item.role = user.role;
      this.item.max_urban_renewal = user.max_urban_renewal;
      this.item.max_issue = user.max_issue;
      this.item.line_account = user.line_account;
      this.item.company_name = user.company_name;
      this.item.job_title = user.job_title;
      this.item.company = user.company;
      this.item.per_charge = user.per_charge;
    },
    checkPassword(password1, password2) {
      let is_valid = true;
      if (password1 != password2) {
        alert(this.$i18n.t("user_mgr.passwordNotMatch"));
        is_valid = false;
      } else if (password1 == "") {
        alert(this.$i18n.t("user_mgr.missingPassword"));
        is_valid = false;
      }
      return is_valid;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data_valid = this.checkPassword(
        this.item.password1,
        this.item.password2
      );
      if (!data_valid) {
        this.loading = false;
        return;
      }
      let data = {
        username: this.item.username,
        first_name: this.item.first_name,
        password: this.item.password1,
        email: this.item.email,
        nickname: this.item.nickname,
        phone: this.item.phone,
        role: this.item.role,
        max_urban_renewal: this.item.max_urban_renewal,
        max_issue: this.item.max_issue,
        line_account: this.item.line_account,
        company_name: this.item.company_name,
        job_title: this.item.job_title,
        company_id: this.item.company_id,
        per_charge: this.item.per_charge,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.close();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/user/" + this.userId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        username: this.item.username,
        first_name: this.item.first_name,
        email: this.item.email,
        nickname: this.item.nickname,
        phone: this.item.phone,
        role: this.item.role,
        max_urban_renewal: this.item.max_urban_renewal,
        max_issue: this.item.max_issue,
        line_account: this.item.line_account,
        company_name: this.item.company_name,
        job_title: this.item.job_title,
        per_charge: this.item.per_charge,
      };
      this.axios
        .patch(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.close();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    resetUserPassword(user) {
      this.dialog_password = true;
      this.userId = user.id;
      this.resetItem.username = user.username;
      this.resetItem.nickname = user.nickname;
      this.resetItem.password1 = "";
      this.resetItem.password2 = "";
    },
    resetPassword(user) {
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/user/" + this.userId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data_valid = this.checkPassword(
        this.resetItem.password1,
        this.resetItem.password2
      );
      if (!data_valid) {
        this.loading = false;
        return;
      }
      let data = {
        password: this.resetItem.password1,
      };
      this.axios
        .patch(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.closePassword();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("reset-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    openDatetime() {
      this.$refs.datetime.isOpen = true;
    },
    RemoveUser(item) {
      if (
        !confirm(
          this.$i18n.t("common.confirm") + this.$i18n.t("common.delete") + "？"
        )
      ) {
        return;
      }
      if (item.role == UserRole.Admin) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/user/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, {}, config)
        .then(function (response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    sendFile() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/user/uploadManual/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let formData = new FormData();
      formData.append("uploadFile", this.uploadFile);
      this.axios
        .post(url, formData, config)
        .then(function (response) {
          currentObj.loading = false;
          if (response.data.status != 1) {
            alert("創建失敗，請檢查上傳檔案格式/內容");
            return;
          }
          alert("送出成功");
          currentObj.closeUpload();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    //cash flow page
    toCashFlow(item) {
      this.$router.push({
        name: "CashMgr",
        query: { user_id: item.id },
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("user_mgr.title"));
    this.getApi();
  },
};
</script>

<style lang="sass" scope>
.v-form
  width: 100%
  .v-btn__content
    a
      color: #FFFFFF
      text-decoration: none
</style>
