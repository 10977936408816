const signInType = {
  personal: 1,
  commission: 2,
  cancel: 3,
};

const UserRole = {
  Admin: 1,
  Unpaid: 2,
  Common: 3,
  CompanyAdmin: 4,
  CompanyUser: 5,
};

const UserRoleItems = [
  { text: "user_role.Admin", value: UserRole.Admin },
  { text: "user_role.Unpaid", value: UserRole.Unpaid },
  { text: "user_role.Common", value: UserRole.Common },
  { text: "user_role.CompanyAdmin", value: UserRole.CompanyAdmin },
  { text: "user_role.CompanyUser", value: UserRole.CompanyUser },
];

const CreateUserRoleItems = [
  { text: "user_role.Common", value: UserRole.Common },
];

const InvoiceType = {
  Einvoice: 1,
  Donate: 2,
  PrintFlag: 3,
};

const InvoiceItems = [
  { text: "invoice.Einvoice", value: InvoiceType.Einvoice },
  { text: "invoice.donate", value: InvoiceType.Donate },
];

const CompanyInvoiceItems = [
  { text: "invoice.printFlag", value: InvoiceType.PrintFlag },
];

const DonatedItems = [
  { text: "donated.test1", value: "121" },
  { text: "donated.test2", value: "122" },
  { text: "donated.test3", value: "123" },
  { text: "donated.otherDonated", value: "other" },
];

const ProductType = {
  Urban: 1,
  Issue: 2,
  Transcript: 3,
};

const ProductTypeItems = [
  { text: "product.Urban", value: ProductType.Urban },
  { text: "product.Issue", value: ProductType.Issue },
  // { text: 'product.Transcript', value: ProductType.Transcript},
];

const InvoiceStatus = {
  Init: 1,
  Touched: 2,
  Invalid: 3,
  Allowance: 4,
  TouchAllowance: 5,
  CancelAllowance: 6,
  InvalidAllowance: 7,
  Cancel: 8,
  Falied: 9,
};

const InvoiceStatusItems = [
  { text: "invoice.status.Init", value: InvoiceStatus.Init },
  { text: "invoice.status.Touched", value: InvoiceStatus.Touched },
  { text: "invoice.status.Invalid", value: InvoiceStatus.Invalid },
  { text: "invoice.status.Allowance", value: InvoiceStatus.Allowance },
  {
    text: "invoice.status.TouchAllowance",
    value: InvoiceStatus.TouchAllowance,
  },
  {
    text: "invoice.status.CancelAllowance",
    value: InvoiceStatus.CancelAllowance,
  },
  {
    text: "invoice.status.InvalidAllowance",
    value: InvoiceStatus.InvalidAllowance,
  },
  { text: "invoice.status.Cancel", value: InvoiceStatus.Cancel },
  { text: "invoice.status.Falied", value: InvoiceStatus.Falied },
];

const LBType = {
  Land: 1,
  Building: 2,
};

const LBTypeItems = [
  { text: "task.land", value: LBType.Land },
  { text: "task.building", value: LBType.Building },
];

const TaskStatus = {
  Init: 0,
  Ongoing: 1,
  Complete: 2,
  Failed: 3,
  Cancel: 4,
  Timeout: 5,
};

const TaskStatusItems = [
  { text: "task.status.Init", value: TaskStatus.Init },
  { text: "task.status.Ongoing", value: TaskStatus.Ongoing },
  { text: "task.status.Complete", value: TaskStatus.Complete },
  { text: "task.status.Failed", value: TaskStatus.Failed },
  { text: "task.status.Cancel", value: TaskStatus.Cancel },
  { text: "task.status.Timeout", value: TaskStatus.Timeout },
];

const SocketEventType = {
  VoteRecord: 1,
  SignInRecord: 2,
  TranscriptParseStart: 3,
  TranscriptParseEvent: 4,
  TranscriptParseEnd: 5,
};

const TranscriptType = {
  Telex: 0,
  Class_1: 1,
  Class_2: 2,
  Class_3: 3,
};

const ImportState = {
  Init: 0,
  Parsing: 1,
  Parsed: 2,
  Paid: 3,
  Processing: 4,
  Completed: 5,
  Importing: 6,
  Failed: -1,
};

export {
  signInType,
  UserRole,
  UserRoleItems,
  CreateUserRoleItems,
  InvoiceItems,
  CompanyInvoiceItems,
  DonatedItems,
  InvoiceStatus,
  InvoiceStatusItems,
  ProductType,
  ProductTypeItems,
  LBType,
  LBTypeItems,
  TaskStatus,
  TaskStatusItems,
  SocketEventType,
  TranscriptType,
  ImportState,
};
