<template>
  <v-container id="md5-model-tables" fluid tag="section">
    <base-material-card
      icon="mdi-database"
      :title="$t('md5.title')"
      class="px-5 py-3"
    >
      <!-- Search Input and Button -->
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            :label="$t('md5.search')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn color="primary" @click="performSearch">{{ $t('md5.search') }}</v-btn>
          <v-btn class="ml-2" @click="search = ''; performSearch()">{{ $t('md5.reset') }}</v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template slot="no-data">
          <div>
            {{ $t("common.noData") }}
          </div>
        </template>
        <!-- add action -->
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn color="primary" class="mx-2" small @click="openEditDialog(item)">{{
              $t("md5.edit")
            }}</v-btn>
          </v-flex>
        </template>
      </v-data-table>
      <!-- Edit Dialog -->
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("md5.edit") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editItem.data"
                    :label="$t('md5.data')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateData">
              {{ $t("common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    editDialog: false,
    editItem: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("md5.md5"),
        value: "md5",
      },
      {
        sortable: false,
        text: vm.$i18n.t("md5.data"),
        value: "data",
      },
      {
        sortable: false,
        text: vm.$i18n.t("md5.create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("md5.actions"),
        value: "actions",
      },
    ],
    search: "",
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/plan/md5/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      if (this.search) {
        config.params.search = this.search;
        config.params.page = 1;
      }
      console.log("getApi", url, config);
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          currentObj.loading = false;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data.results;
    },
    openEditDialog(item) {
      this.editItem = { ...item };
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    updateData() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/plan/md5/" + this.editItem.id + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, { data: this.editItem.data }, config)
        .then(function (response) {
          currentObj.loading = false;
          alert(currentObj.$t("md5.updateSuccess"));
          currentObj.getApi();
          currentObj.closeEditDialog();
        })
        .catch(function (error) {
          console.log(error);
          currentObj.loading = false;
        });
    },
    performSearch() {
      this.getApi();
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.getApi();
  },
};
</script>

<style lang="sass" scope>
.v-data-table
  width: 100%
</style>
